<script setup>
import { computed, ref, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import CustomerSingle from './subComponents/CustomerSingle'

// Define main items
const store = useStore()
const route = useRoute()
const router = useRouter()

// Handle loadings
const firstLoad = ref(true)
const pageStatus = computed(() => store.state.customer.pageStatus)
watch(pageStatus, (curr) => {
  if (curr == 'resolved') firstLoad.value = false
})

const customers = computed(() => store.state.customer.items)
const url = '/store/customer/'
const getCustomers = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('customer/fetchRecords', { url, query: route.query })
}
if (customers.value.length === 0) getCustomers()

// Handle routing
const enterCustomer = (id) => {
  router.push({ name: 'CustomerShow', params: { id } })
}

const registerSell = (mobile) => {
  store.commit('UPDATE_FORM_ITEMS', {
    field: 'customerMobile',
    value: mobile
  })
  store.dispatch('handleModalFooter', true)
}

// Cleaning the room
onBeforeUnmount(() => store.dispatch('customer/clearItems'))
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="جستجوی مشتری" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode
      v-else-if="pageStatus == 'failed' && firstLoad"
      @refresh="getCustomers"
    />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <template v-for="customer in customers" :key="customer['id']">
          <CustomerSingle
            :name="customer['name']"
            :image="customer['image']"
            title="موبایل"
            :value="customer['mobile']"
            info-type="mobile"
            @enterClicked="enterCustomer(customer['id'])"
            @sellClicked="registerSell(customer['mobile'])"
          />
        </template>
      </div>
      <Loader v-if="pageStatus == 'loading'" />
      <FailedMode
        v-else-if="pageStatus == 'failed' && !firstLoad"
        @refresh="getCustomers"
      />
      <Observer @intersect="getCustomers" />
    </section>
    <FixedFooter />
  </div>
</template>

<style lang="scss" scoped>
// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
