<script setup>
import { ref, computed, defineProps } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: 'نام مشتری'
  },
  mobile: { type: String, default: '09100000000' },
  gender: { type: String },
  image: {
    type: String
  },
  group: {
    type: String,
    default: 'بدون سطح'
  }
})

// Add +98 for whatsapp
const waMobile = computed(() => {
  return '+98' + props.mobile.substr(1)
})

const flip = ref(false)
</script>

<template>
  <div class="customer-info">
    <a :href="`tel:${mobile}`" class="customer-btns">
      <svg viewBox="0 0 700 512">
        <path
          d="M237.12 512c-6.87,-0.01 -13.73,-0.52 -20.53,-1.51 -22,-3.24 -43.22,-11.75 -61.42,-24.51 -18.3,-12.83 -33.63,-29.98 -44.22,-49.67 -11.33,-21.05 -16.94,-43.92 -16.94,-67.83 0,-6.63 4.62,-12.36 11.11,-13.75l118.14 -25.31c4.66,-1 9.51,0.43 12.89,3.81l44.31 44.31 1.33 -0.67c81.52,-41.01 148.08,-107.55 189.08,-189.07l0.68 -1.34 -44.32 -44.32c-3.37,-3.37 -4.81,-8.23 -3.81,-12.89l25.32 -118.14c1.39,-6.48 7.12,-11.11 13.74,-11.11 23.92,0 46.79,5.63 67.84,16.94 19.69,10.59 36.84,25.92 49.66,44.23 12.75,18.19 21.28,39.42 24.5,61.42 3.46,23.52 0.95,46.81 -7.18,69.15 -25.81,71.09 -67.8,136.81 -121.28,190.29 -53.48,53.47 -119.19,95.45 -190.27,121.28 -15.65,5.67 -31.99,8.69 -48.63,8.69l0 0zm236.61 -483.38l-21.22 99.04 45.98 45.98c4.17,4.18 5.29,10.58 2.79,15.92 -44.87,95.51 -122.21,172.84 -217.73,217.71 -5.33,2.51 -11.74,1.39 -15.91,-2.78l-45.97 -45.98 -99.04 21.23 0.2 1.86c3.66,33.18 21.13,62.21 48.47,81.36 30.94,21.67 69.33,26.82 104.86,13.92 137.38,-49.9 244.81,-157.34 294.72,-294.73 12.9,-35.51 7.75,-73.92 -13.92,-104.85 -19.16,-27.33 -48.18,-44.8 -81.36,-48.47l-1.87 -0.21 0 0z"
        />
      </svg>
    </a>
    <div class="customer-img">
      <img v-show="flip" :src="image" @load="flip = true" />
      <svg
        v-if="!flip"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 512 512"
      >
        <circle fill="#606062" cx="256" cy="256" r="256" />
        <path
          fill="#373435"
          d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
        />
      </svg>
    </div>
    <a :href="`https://wa.me/${waMobile}`" class="customer-btns">
      <svg viewBox="0 0 506.964 506.964">
        <path
          d="M252.321-1C113.83-1,1.357,111.472,1.357,249.125c0,43.646,11.751,85.613,33.574,124.223L-2,505.964l135.134-36.092
				c39.449,20.984,80.577,32.734,119.187,32.734c136.813,0,252.643-115.83,252.643-253.482S391.652-1,252.321-1z M252.321,485.82
				c-36.931,0-76.38-11.751-114.151-32.734l-3.357-1.679L21.502,482.462L52.557,370.83l-1.679-3.357
				c-20.984-36.092-32.734-77.22-32.734-118.348c0-128.42,104.918-233.338,234.177-233.338
				c130.098,0,235.856,104.918,235.856,233.338S379.902,485.82,252.321,485.82z"
        />
        <path
          d="M252.321,40.967c-114.99,0-208.997,93.167-208.997,208.157c0,41.128,11.751,79.738,32.734,114.151l3.357,4.197
				L56.754,447.21l82.256-20.984l4.197,2.518c34.413,20.984,72.184,31.895,109.115,31.895c115.83,0,210.675-94.846,210.675-211.515
				C462.997,134.134,368.151,40.967,252.321,40.967z M251.482,444.692c-33.574,0-67.987-10.911-99.882-30.216l-10.072-6.715
				l-61.272,15.108l16.787-58.754l-6.715-10.911c-20.144-31.056-30.216-67.148-30.216-104.079
				c0-104.918,86.452-191.37,192.21-191.37c106.597,0,193.889,86.452,193.889,191.37
				C446.21,356.561,358.918,443.852,251.482,444.692z"
        />
        <path
          d="M378.223,295.289l-52.879-24.341c-7.554-3.357-15.108-0.839-20.144,5.036l-20.144,26.02
				c-15.108-5.875-57.075-24.341-81.416-69.666l18.466-21.823c4.197-5.036,5.036-11.751,2.518-17.626l-22.662-53.718
				c-2.518-5.875-7.554-10.072-14.269-10.072l-15.948-0.839c-6.715,0-14.269,1.679-19.305,6.715
				c-7.554,6.715-21.823,20.984-26.02,40.289c-5.875,27.698,3.357,60.433,26.859,92.328
				c14.269,20.144,57.915,79.738,141.849,103.239c8.393,2.518,16.787,3.357,24.341,3.357c14.269,0,27.698-4.197,39.449-10.911
				c13.43-8.393,22.662-22.662,26.02-37.771l2.518-11.751C389.134,306.2,384.938,298.646,378.223,295.289z M367.311,321.308
				c-2.518,10.911-9.233,20.984-18.466,26.859c-14.269,9.233-31.056,10.911-49.521,5.875
				c-78.059-21.823-119.187-78.059-132.616-96.525c-20.144-27.698-28.538-56.236-23.502-78.898
				c3.357-15.108,14.269-26.02,20.984-31.895c1.679-1.679,4.197-2.518,6.715-2.518l16.787,1.679l22.662,52.879l-20.144,22.662
				c-3.357,4.197-4.197,10.911-1.679,15.948c27.698,53.718,78.059,74.702,93.167,79.738c5.875,1.679,11.751,0,15.948-5.036
				l20.144-26.859l52.879,24.341L367.311,321.308z"
        />
      </svg>
    </a>
  </div>
  <div class="customer-top">
    <div class="customer-name">
      <h1 class="customer-name__text">
        <span>{{ gender === '1' ? 'آقای' : 'خانم' }}</span>
        <span style="opacity: 1">/</span>
        {{ name }}
      </h1>
    </div>
    <div class="customer-sort">
      <h6 class="customer-sort__text" v-text="group ?? 'بدون سطح'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.customer-info {
  width: 100%;
  margin: 10px 0 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .customer-img {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      border-radius: 100%;
      width: 120px;
      height: 120px;
      filter: saturate(0.3);
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
    }
    svg {
      border-radius: 100%;
      width: 120px;
      height: 120px;
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
    }
  }
  .customer-btns {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(#000, 0.1);
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
      fill: #c69d4e;
    }
  }
}
.customer-top {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .part-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0px 0 5px;
    h2 {
      position: relative;
      bottom: 0px;
      color: #777;
      margin: 0 0 0 0;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      svg {
        position: relative;
        bottom: 2px;
        margin-right: -2px;
        width: 17px;
        height: 17px;
        .fil0 {
          fill: #777;
        }
      }
    }
  }
}

.customer-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-name__text {
  color: black;
  margin: 0 0 15px;
  text-align: center;
  font-size: 18px;
  // font-weight: bold;
  margin: 0;
  &::before {
    // color: #777;
    margin-left: 3px;
  }
}

.customer-sort {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.customer-sort__text {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  color: #777;
  &::before {
    content: 'سطح‌';
    margin-left: 3px;
    color: #c69d4e;
  }
}
</style>
