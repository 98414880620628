<script setup>
import { defineProps, computed, ref } from 'vue'

const props = defineProps({
  // 1: active -  2: expired - 3: manually turned off
  status: { type: String, default: '1' },
  newPrice: {
    type: Number,
    default: 0
  },
  oldPrice: {
    type: Number,
    default: 0
  },
  count: {
    type: Number,
    default: 0
  },
  image: {
    type: String
  }
})

const isDark = computed(() => props.status != 1)

const flip = ref(false)
</script>

<template>
  <div class="single-offer-wrapper">
    <div :class="['offer', isDark && 'offer--dark']">
      <div class="offer__off">OFF</div>
      <div class="offer__filter" />
      <div class="offer__inner">
        <img
          class="offer__figure"
          :src="image"
          v-show="flip"
          @load="flip = !flip"
        />
        <img src="@img/preload-logo.svg" class="offer__figure" v-if="!flip" />
        <div class="offer__prices">
          <h2 class="offer__percent">
            {{ Math.abs(100 - Math.floor((newPrice / oldPrice) * 100)) }}
          </h2>
          <h3
            class="offer__price price--old"
            v-text="oldPrice.toLocaleString()"
          />
          <h3
            class="offer__price price--new"
            v-text="newPrice.toLocaleString()"
          />
        </div>
        <div class="offer__circles-col">
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.single-offer-wrapper {
  position: relative;
  padding: 0;
  margin: 0 0 10px;
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
$top-radius: 8px;
.offer {
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
}
.offer__filter {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(#fff, 0.1) 20px,
    rgba(#fff, 1) 180px,
    #fff 100%
  );
}
.offer__inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  padding: 0;
}
.offer__figure {
  width: 100%;
  height: 180px;
  margin: 0;
  object-fit: cover;
  object-position: center;
  padding: 0;
  cursor: pointer;
  filter: saturate(0.75);
}
.offer__prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: flex-end;
  z-index: 2;
  bottom: 25px;
}
.offer__price {
  position: relative;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  // padding-top: 6px;
  &::after {
    content: 'R';
    font-size: 12px;
    top: -6px;
    left: 3px;
    position: relative;
  }
}
.price--old {
  color: #777;
  // margin-top: 20px;
  &::before {
    content: '';
    position: absolute;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.4);
    height: 2px;
    top: 0;
    bottom: 6px;
    left: -10%;
    margin: auto 0;
  }
}
.price--new {
  color: #c69d4e;
}
.offer__off {
  position: absolute;
  z-index: 3;
  background-color: #c69d4e;
  width: 90px;
  height: 90px;
  left: -45px;
  top: -45px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transform: rotate(-45deg);
  color: #000;
  font-weight: bold;
}
.offer__percent {
  color: #000;
  margin: 0 0 3px;
  font-size: 40px;
  position: relative;
  left: -7px;
  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 8px;
    margin: auto 0;
    width: 12px;
    height: 2px;
    background-color: #000;
  }
  &::after {
    content: '%';
    position: absolute;
    right: -32px;
    top: 0;
    bottom: 0px;
    margin: auto 0;
    // width: 12px;
    // height: 2px;
    // background-color: #000;
  }
}
.offer__circles-col {
  z-index: 4;
  position: absolute;
  bottom: -7px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
}
.offer__circle {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  border-radius: 100%;
  background-color: #eee;
}
// modifier
.offer--dark {
  background-color: #131313;
}
.offer--dark .offer__filter {
  background: linear-gradient(
    to bottom,
    rgba(#131313, 0.2) 20px,
    rgba(#131313, 1) 160px,
    #131313 100%
  );
  box-shadow: none;
}
.offer--dark .offer__percent {
  color: #333;
  &::before {
    background-color: #333;
  }
}
.offer--dark .offer__off {
  background-color: #444;
}
.offer--dark .offer__price::after {
  color: #333;
}
.offer--dark .price--old {
  color: #333;
}
.offer--dark .price--old::before {
  background-color: #333;
}
.offer--dark .price--new {
  color: #333;
}
.offer--dark .price--new::after {
  color: #333;
}
.offer--dark .offer__figure {
  filter: saturate(0) opacity(0.6);
}
</style>
