<script setup>
import { ref, watch, computed, onBeforeUnmount, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getCustomer } from '@service/CustomerService'
import { createTicket } from '@service/TicketService'
import dateConvertor from '@composable/dateConvertor'
import SatisfactionSingle from '@view/store/home/subComponents/SatisfactionSingle'

const props = defineProps(['id'])
const store = useStore()

const comments = computed(() => store.state.comment.items)
const pageStatus = computed(() => store.state.comment.pageStatus)

const url = '/store/transaction/'
const query = { star: 0, wallet: props.id }
const getComments = async () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('comment/fetchRecords', { url, query })
}
getComments()

// Reply customer
const router = useRouter()
const replyCustomer = async (id) => {
  let ticketId
  try {
    const { data: customer } = await getCustomer(id)
    if (customer['ticket_id'] > 0) ticketId = customer['ticket_id']
    else {
      try {
        const { data } = await createTicket(id)
        ticketId = data['ticket_id']
      } catch (ex) {
        console.log(ex)
      }
    }

    router.push({ name: 'TicketShow', params: { id: ticketId } })
  } catch (ex) {
    console.log(ex)
  }
}

// Handle two loadings
const firstLoad = ref(true)
watch(pageStatus, (curr) => {
  if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
})

// Get necessary items for date
const { localizeDate, getHour } = dateConvertor()

onBeforeUnmount(() => store.dispatch('comment/clearItems'))
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="نظرهای مشتری" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode @refresh="getComments" v-if="pageStatus == 'failed'" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <SatisfactionSingle
          v-for="comment in comments"
          :key="comment['id']"
          :rating="comment['star']"
          :date="localizeDate(comment['created_at'])"
          :hour="getHour(comment['created_at'])"
          :desc="comment['desc']"
          :price="comment['price']"
          :name="comment['name']"
          :img="comment['image']"
          :group="comment['group']"
          :mobile="comment['mobile']"
          :customer-id="comment['wallet_id']"
          :has-profile="false"
          @reply="replyCustomer(comment['wallet_id'])"
        />
      </div>
      <Observer @intersect="getComments" />
      <Loader v-if="pageStatus == 'loading' && !firstLoad" />
    </section>
    <FixedFooter />
  </div>
</template>

<style scoped lang="scss">
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
