<template>
  <div class="cmp-wrapper">
    <navbar msg="آفرهای مشتری" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="cmp-items-wrapper">
        <offer-single
          v-for="offer in offers"
          :key="offer['id']"
          :status="offer['status']"
          :old-price="offer['price']"
          :new-price="offer['price_final']"
          :image="offer['image']"
          :count="offer['count']"
          @click="enterOffer(offer['id'])"
        />
      </div>
      <div class="cmp-items--empty" v-if="offers.length === 0">
        برای این مشتری آفر ایجاد نشده است.
      </div>
    </section>
    <failed-mode @refresh="getOffers" v-else />
    <fixed-footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getCustomerOffers } from '@service/CustomerService'
import OfferSingle from '@view/store/offer/subComponents/OfferSingle'

export default {
  name: 'CustomerOffer',
  components: {
    OfferSingle
  },
  props: ['id'],
  setup(props) {
    const offers = ref(null)
    const pageStatus = ref('loading')

    const getOffers = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getCustomerOffers(props.id)
        offers.value = data
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    getOffers()

    const router = useRouter()
    const enterOffer = (id) => {
      router.push({ name: 'OfferShow', params: { id } })
    }

    return { offers, pageStatus, enterOffer, getOffers }
  }
}
</script>

<style scoped lang="scss">
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
.cmp-items-wrapper {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
