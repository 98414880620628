<script setup>
import { computed, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'
import days from '@composable/jalaliDays'

const props = defineProps({
  id: [Number, String],
  buyCount: {
    type: [Number, String],
    default: 0
  },
  lastBuy: {
    type: String
  },
  firstBuy: {
    type: String
  },
  age: {
    type: [Number, String],
    default: 0
  },
  birthDay: {
    type: [String, Number]
  },
  city: {
    type: String
  },
  completed: {
    type: Boolean,
    default: false
  },
  buySum: {
    type: [String, Number],
    default: 0
  },
  earn: {
    type: [String, Number],
    default: 0
  },
  pay: {
    type: [String, Number],
    default: 0
  },
  amount: {
    type: [String, Number],
    default: 0
  },
  rating: {
    type: [String, Number],
    default: 0
  }
})

const { localizeDate, getRemainingDay } = dateConvertor()

const lastBuyDay = computed(() => {
  const day = getRemainingDay(new Date(props.lastBuy), new Date())
  return day < 0 ? 0 : day
})

const lastBuyDate = computed(() => {
  return props.lastBuy ? localizeDate(props.lastBuy) : ''
})

const firstBuyDay = computed(() => {
  const day = getRemainingDay(new Date(props.firstBuy), new Date())
  return day < 0 ? 0 : day
})

const firstBuyDate = computed(() => {
  return props.firstBuy ? localizeDate(props.firstBuy) : ''
})

const formattedBd = computed(() => {
  if (!props.completed) return 'تکمیل نشده'
  if (props.birthDay === 0) return 'تکمیل نشده'

  let bd = props.birthDay
  if (bd < 1000) {
    bd = '0' + bd
  }
  const bdText = days.find((day) => day.code === String(bd))
  return bdText.html
})

// Enter satisfy page
const router = useRouter()
const store = useStore()
const enterSat = () => {
  if (props.rating === 0)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'نظری تا کنون ثبت نشده است.'
    })

  router.push({ name: 'CustomerSatisfaction', params: { id: props.id } })
}
</script>

<template>
  <div class="info">
    <section class="info-boxes">
      <div class="info-box">
        <h4 class="info-box__title">اولین خرید</h4>
        <h3 class="info-box__today" v-if="firstBuyDay === 0">امروز</h3>
        <template v-else>
          <h3 class="info-box__day" v-text="firstBuyDay" />
          <span class="info-box__text">روز پیش</span>
        </template>
      </div>
      <i class="info-box__vr" />
      <div class="info-box">
        <h4 class="info-box__title">تعداد خرید</h4>
        <h3 class="info-box__day" v-text="buyCount" />
        <span class="info-box__text">مرتبه</span>
      </div>
      <i class="info-box__vr" />
      <div class="info-box">
        <h4 class="info-box__title">آخرین خرید</h4>
        <h3 class="info-box__today" v-if="lastBuyDay === 0">امروز</h3>
        <template v-else>
          <h3 class="info-box__day" v-text="lastBuyDay" />
          <span class="info-box__text">روز پیش</span>
        </template>
      </div>
    </section>
    <section class="info-table">
      <div class="info-table__col">
        <h5 class="info-table__cell">سن</h5>
        <h6 class="info-table__cell" v-text="age > 0 ? age : 'تکمیل نشده'" />
      </div>
      <i class="info-table__vr" />
      <div class="info-table__col">
        <h5 class="info-table__cell">روز تولد</h5>
        <h5 class="info-table__cell" v-html="formattedBd" />
      </div>
      <i class="info-table__vr" />
      <div class="info-table__col">
        <h5 class="info-table__cell">شهر</h5>
        <h5 class="info-table__cell" v-text="city ?? 'تکمیل نشده'" />
      </div>
    </section>
    <a @click="enterSat" class="info-sat">
      <h5 class="info-sat__percent" v-text="Math.round(rating * 20) + '%'" />
      <h6 class="info-sat__text">رضایت‌ مندی</h6>
    </a>
    <section class="info-report">
      <i class="info-report__hr" />
      <div class="info-report__row">
        <h5 class="info-report__title">مبلغ خرید</h5>
        <h6 class="info-report__value" v-text="buySum?.toLocaleString()" />
      </div>
      <i class="info-report__hr" />
      <div class="info-report__row">
        <h5 class="info-report__title">دریافت اعتبار</h5>
        <h6
          :class="['info-report__value', earn > 0 && 'value--plus']"
          v-text="earn?.toLocaleString()"
        />
      </div>
      <i class="info-report__hr" />
      <div class="info-report__row">
        <h5 class="info-report__title">خرید با اعتبار</h5>
        <h6
          :class="['info-report__value', pay > 0 && 'value--minus']"
          v-text="pay?.toLocaleString()"
        />
      </div>
      <i class="info-report__hr" />
      <div class="info-report__row">
        <h5 class="info-report__title">موجودی کیف پول</h5>
        <h6 class="info-report__value" v-text="amount?.toLocaleString()" />
      </div>
      <i class="info-report__hr" />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #131313;
  width: 100%;
  border-radius: 0;
  padding: 20px 0 0;
  position: relative;
}

// Info box
.info-boxes {
  width: calc(100% - 20px);
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  background: linear-gradient(to bottom, #ddd 0, #fff 80%);
  border-radius: 15px;
  overflow: hidden;
}
.info-box {
  width: calc(33% - 1px);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-box__title {
  color: #c69d4e;
  font-size: 14px;
  margin: 14px 0 8px;
}
.info-box__today {
  box-shadow: 0 0 10px 1px rgba(#000, 0.1);
  color: #000;
  border-radius: 10px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.info-box__day {
  font-size: 30px;
  margin: 4px 0 14px;
  padding-top: 5px;
  height: 20px;
  font-weight: bold;
  line-height: 20px;
}
.info-box__text {
  color: #777;
  font-size: 14px;
  margin: 0 0 8px;
}
.info-box__date {
  color: #aaa;
  font-size: 14px;
  margin: 0;
}
.info-box__vr {
  height: 80%;
  width: 1px;
  background-color: rgba(#000, 0.1);
}

// Table
$info-table-border-color: rgba(#000, 0.1);
.info-table {
  overflow: hidden;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  height: 85px;
}
.info-table__col {
  width: calc(33.33% - 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.info-table__cell {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #000;
  &:first-child {
    margin-bottom: 10px;
    color: #c69d4e;
  }
}
.info-table__vr {
  height: 80%;
  width: 1px;
  background-color: $info-table-border-color;
}

.info-sat {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 100%;
  border: 5px solid #c69d4e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
  cursor: pointer;
  background-color: #000;
}
.info-sat__percent {
  font-size: 40px;
  color: #fff;
  position: relative;
  top: 2px;
  animation: blinking 1.3s infinite;
}
.info-sat__text {
  margin-top: 3px;
  color: #fff;
  font-size: 16px;
}

.info-report {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
}
.info-report__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 13px;
  padding: 0 10px 0 15px;
}
.info-report__title {
  font-size: 14px;
  margin: 0;
  color: #111;
}
.info-report__hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}
.info-report__value {
  direction: ltr;
  text-align: left;
  position: relative;
  font-size: 16px;
  margin-left: 10px;
  color: #111;
  &::before {
    position: absolute;
    left: -20px;
  }
  &::after {
    position: absolute;
    content: 'R';
    color: #333;
    font-size: 10px;
    right: -10px;
    top: -0.5px;
  }
}
.value--plus::before {
  content: '+';
}
.value--minus::before {
  content: '-';
}
</style>
