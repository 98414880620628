<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getCustomerByBirth } from '@service/CustomerService'
import CustomerSingle from './subComponents/CustomerSingle'

// Define main items
const modals = reactive({
  filter: false
})
const customers = ref(null)
const pageStatus = ref('')

const getCustomers = async (day) => {
  pageStatus.value = 'loading'
  try {
    const { data } = await getCustomerByBirth(day)
    customers.value = data
    pageStatus.value = 'resolved'
  } catch (ex) {
    pageStatus.value = 'failed'
  }
}

// Initial api call for today
getCustomers(0)

// Handle routing
const router = useRouter()
const enterCustomer = (id) => {
  router.push({ name: 'CustomerShow', params: { id } })
}

// Handle filters
const selectedFilter = ref({
  day: 0,
  uiText: 'امروز'
})
const filters = [
  { day: 0, uiText: 'امروز' },
  { day: 1, uiText: 'فردا' },
  { day: 2, uiText: '2 روز دیگر' },
  { day: 3, uiText: '3 روز دیگر' },
  { day: 4, uiText: '4 روز دیگر' },
  { day: 5, uiText: '5 روز دیگر' },
  { day: 6, uiText: '6 روز دیگر' },
  { day: 7, uiText: '7 روز دیگر' }
]
const selectFilter = (filter) => {
  selectedFilter.value = filter
  getCustomers(filter['day'])
  modals.filter = false
}
</script>

<template>
  <div class="cmp-wrapper">
    <navbar msg="تولد" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <a class="customer-btn" @click="modals['filter'] = true">
        <span>{{ selectedFilter['uiText'] }}</span>
        <svg viewBox="0 0 512 512" class="customer-btn__arrow">
          <path
            d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
          />
        </svg>
      </a>
      <div class="cmp-items-wrapper">
        <template v-for="customer in customers" :key="customer['id']">
          <customer-single
            :name="customer['name']"
            :image="customer['image']"
            title="سطح مشتری"
            :value="customer['group']"
            info-type="normal"
            @click="enterCustomer(customer['id'])"
          />
        </template>
      </div>
    </section>
    <modal
      :is-open="modals['filter']"
      @modalClosed="modals['filter'] = false"
      title="روز تولد"
    >
      <div class="available-filter">
        <template v-for="filter in filters" :key="filter">
          <div class="filter-row" @click="() => selectFilter(filter)">
            <h5 :class="filter.day === selectedFilter.day && 'gold'">
              {{ filter['uiText'] }}
            </h5>
          </div>
          <hr />
        </template>
      </div>
    </modal>
    <fixed-footer />
  </div>
</template>

<style lang="scss" scoped>
.customer-btn {
  position: relative;
  background-color: #ddd;
  height: 70px;
  padding-bottom: 5px;
  margin: 15px 0;
  width: 96%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #000;
    font-size: 15px;
  }
}
.customer-btn__arrow {
  height: 18px;
  fill: #aaa;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: rotate(-90deg);
}
.available-filter {
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .filter-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      text-align: center;
      width: 100%;
      color: #111;
      font-size: 16px;
      margin: 5px 0;
      &.gold {
        color: #c69d4e;
      }
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
.filter-exp {
  margin: 15px 0 10px;
  color: #777;
}

// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
