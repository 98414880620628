<script setup>
import { computed, ref, watchEffect, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const cities = computed(() => store.state.customer.cities)

// Handle page status
const pageStatus = ref('')
if (cities.value['cities']) pageStatus.value = 'resolved'
else {
  pageStatus.value = 'loading'
  store.dispatch('customer/fetchCustomerCities')
}

watchEffect(() => {
  if (cities.value['cities']) pageStatus.value = 'resolved'
})

onBeforeUnmount(() => store.dispatch('customer/clearCustomerCities'))
</script>

<template>
  <div class="cmp-wrapper">
    <navbar msg="شهر ها" />
    <div class="bg-color" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="all-cities">
        <template v-for="city in cities['cities']" :key="city['id']">
          <router-link
            :to="{
              name: 'Customer',
              query: { city: city['id'], type: 5, cityName: city['name'] }
            }"
            class="city-row"
          >
            <div class="first-col">
              <svg viewBox="0 0 512 512">
                <path
                  d="M256 0c-99.25,0 -180,80.75 -180,180 0,33.53 9.29,66.26 26.87,94.65l142.88 230.26c2.74,4.41 7.56,7.09 12.75,7.09 0.04,0 0.08,0 0.12,0 5.23,-0.04 10.06,-2.8 12.75,-7.29l139.24 -232.49c16.61,-27.79 25.39,-59.68 25.39,-92.22 0,-99.25 -80.75,-180 -180,-180zm128.87 256.82l-126.6 211.37 -129.9 -209.34c-14.64,-23.64 -22.57,-50.9 -22.57,-78.85 0,-82.71 67.49,-150.2 150.2,-150.2 82.71,0 150.1,67.49 150.1,150.2 0,27.12 -7.41,53.69 -21.23,76.82z"
                />
                <path
                  d="M256 90c-49.63,0 -90,40.37 -90,90 0,49.31 39.72,90 90,90 50.9,0 90,-41.23 90,-90 0,-49.63 -40.37,-90 -90,-90zm0 150.2c-33.26,0 -60.2,-27.03 -60.2,-60.2 0,-33.08 27.11,-60.2 60.2,-60.2 33.08,0 60.1,27.12 60.1,60.2 0,32.68 -26.32,60.2 -60.1,60.2z"
                />
              </svg>
              <h4>{{ city['name'] }}</h4>
            </div>
            <h5>{{ city['count'].toLocaleString() }}</h5>
            <div class="last-col">
              <h6>
                {{ ((city['count'] / cities['count']) * 100).toFixed(0) }}%
              </h6>
              <svg viewBox="0 0 512 512">
                <path
                  d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
                />
              </svg>
            </div>
          </router-link>
          <hr />
        </template>
      </div>
    </section>
    <fixed-footer />
  </div>
</template>

<style lang="scss" scoped>
.cmp-wrapper {
  padding: 60px 0 75px;
  display: flex;
  z-index: 1;
  .bg-color {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    background: linear-gradient(to bottom, #000 0, #000 30%, #333 100%);
  }
}
.all-cities {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .city-row {
    width: 100%;
    padding: 0 10px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    height: 60px;
    cursor: pointer;
    .first-col {
      display: flex;
      align-items: center;
      svg {
        margin-right: 11px;
        width: 22px;
        height: 22px;
        fill: rgba(#ddd, 0.5);
      }

      h4 {
        justify-self: start;
        line-height: 30px;
        font-size: 14px;
        text-align: right;
        padding-top: 2px;
        color: #eee;
        margin: 0 12px 0 0;
      }
    }

    h5 {
      // width: 33.33%;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      text-align: center;
      color: #ccc;
      margin: 0;
      padding-top: 3px;
      &::after {
        position: relative;
        bottom: 1px;
        content: 'نفر';
        font-size: 14px;
        margin-right: 5px;
        color: #777;
      }
    }

    .last-col {
      // width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      h6 {
        margin: 0;
        display: inline-block;
        color: #111;
        font-weight: bold;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding-top: 2px;
        border-radius: 20px;
        background-color: #c69d4e;
      }
      svg {
        margin-right: 12px;
        fill: #555;
        width: 20px;
        height: 20px;
      }
    }
    i {
      width: 1px;
      height: 100%;
      background-color: #111;
    }
  }
  .row--empty {
    width: 100%;
    text-align: center;
    p {
      grid-column: 1/-1;
    }
  }
  hr {
    width: 94%;
    margin: 0;
    border-top: 1px solid rgba(#fff, 0.1);
    border-radius: 10px;
    &:last-child {
      display: none;
    }
  }
}

.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
