<script setup>
import { computed, onBeforeUnmount, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import CustomerSingle from './subComponents/CustomerSingle'

// Define main items
const store = useStore()
const modals = reactive({
  filter: false
})
const customers = computed(() => store.state.customer.items)

// Handle loadings
const firstLoad = ref(true)
const pageStatus = computed(() => store.state.customer.pageStatus)
watch(pageStatus, (curr) => {
  if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
})

// Initial API call
const url = '/store/customer/'
const route = useRoute()
const getCustomers = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('customer/fetchRecords', {
    url,
    query: route.query
  })
}
getCustomers()

// Handle routing
const router = useRouter()
const enterCustomer = (id) => {
  router.push({ name: 'CustomerShow', params: { id } })
}

// Handle filters
const selectedFilter = ref({
  apiText: 'first_buy',
  uiText: 'تاریخ ثبت'
})
const filters = [
  { apiText: 'first_buy', uiText: 'تاریخ ثبت' },
  { apiText: 'buy_sum', uiText: 'بیشترین مبلغ خرید' },
  { apiText: 'buy_count', uiText: 'بیشترین تعداد خرید' },
  { apiText: 'earn', uiText: 'بیشترین اعتبار دریافتی' },
  { apiText: 'amount', uiText: 'بیشترین موجودی اعتبار' },
  { apiText: 'last_buy', uiText: 'آخرین خرید' }
]
const selectFilter = (filter) => {
  selectedFilter.value = filter
  store.dispatch('customer/clearItems')
  const query = Object.assign({}, route.query)
  query['ordering'] = '-' + filter.apiText
  store.dispatch('customer/fetchRecords', {
    url,
    query
  })
  modals.filter = false
}

// Generate titles
const filterTitle = computed(() => {
  const route = useRoute()
  const filter = Object.keys(route.query)[0]
  switch (filter) {
    case 'gender':
      if (route.query[filter] == 1) return `جنسیت: مرد`
      else if (route.query[filter] == 2) return `جنسیت: زن`
      break

    case 'age_from':
      return `بازه سنی: ${route.query['ageName']}`

    case 'group':
      return `سطح: ${route.query['groupName']}`

    case 'city':
      return `شهر: ${route.query['cityName']}`

    case 'label':
      return `گروه: ${route.query['labelName']}`

    default:
      break
  }

  return ''
})
const innerTitle = computed(() => {
  switch (selectedFilter.value.apiText) {
    case 'first_buy':
      return 'تاریخ ثبت'

    case 'last_buy':
      return 'آخرین خرید'

    case 'buy_sum':
      return 'مبلغ خرید'

    case 'earn':
      return 'اعتبار دریافتی'

    case 'amount':
      return 'موجودی اعتبار'

    case 'buy_count':
      return 'تعداد خرید'

    default:
      return 'تاریخ عضویت'
  }
})
const infoType = computed(() => {
  switch (selectedFilter.value.apiText) {
    case 'first_buy':
      return 'date'

    case 'last_buy':
      return 'date'

    case 'buy_sum':
      return 'money'

    case 'earn':
      return 'money'

    case 'amount':
      return 'money'

    case 'buy_count':
      return 'times'

    default:
      return 'times'
  }
})

// Clean the room
onBeforeUnmount(() => store.dispatch('customer/clearItems'))
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar :msg="filterTitle" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode v-else-if="pageStatus == 'failed'" @refresh="getCustomers" />
    <section class="load-resolved" v-else>
      <a class="customer-btn" @click="modals['filter'] = true">
        <span>{{ selectedFilter['uiText'] }}</span>
        <svg viewBox="0 0 512 512" class="customer-btn__arrow">
          <path
            d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
          />
        </svg>
      </a>
      <div class="cmp-items-wrapper">
        <template v-for="customer in customers" :key="customer['id']">
          <CustomerSingle
            :name="customer['name']"
            :image="customer['image']"
            :title="innerTitle"
            :value="customer?.[selectedFilter['apiText']]"
            :info-type="infoType"
            @click="enterCustomer(customer['id'])"
          />
        </template>
      </div>
      <Loader v-show="pageStatus == 'loading' && !firstLoad" />
      <Observer @intersect="getCustomers" />
    </section>
    <Modal
      :is-open="modals['filter']"
      @modalClosed="modals['filter'] = false"
      title="ترتیب مشتریان بر اساس:"
    >
      <div class="available-filter">
        <template v-for="filter in filters" :key="filter">
          <div class="filter-row" @click="() => selectFilter(filter)">
            <h5 :class="filter.apiText === selectedFilter.apiText && 'gold'">
              {{ filter['uiText'] }}
            </h5>
          </div>
          <hr />
        </template>
      </div>
    </Modal>
    <FixedFooter />
  </div>
</template>

<style lang="scss" scoped>
.customer-btn {
  position: relative;
  background-color: #ddd;
  height: 70px;
  padding-bottom: 5px;
  margin: 15px 0;
  width: 96%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #000;
    font-size: 15px;
  }
}
.customer-btn__arrow {
  height: 18px;
  fill: #aaa;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: rotate(-90deg);
}
.available-filter {
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .filter-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      text-align: center;
      width: 100%;
      color: #111;
      font-size: 16px;
      margin: 5px 0;
      &.gold {
        color: #c69d4e;
      }
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
.filter-exp {
  margin: 15px 0 10px;
  color: #777;
}

// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
