<template>
  <div class="cmp-wrapper">
    <navbar msg="سطح مشتریان" />
    <section class="load-pending load-pending--show" v-if="!resolved">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="table">
        <template v-for="group in groups" :key="group.id">
          <div class="table__part">
            <div class="table__row" v-if="!group['is_default']">
              <h6 class="table__cell">
                {{ group['name'] }}
              </h6>
              <h6 class="table__cell cell--price">
                {{ group['min_buy'].toLocaleString() }}
              </h6>
              <a class="table__btn-edit" @click="editGroup(group)">
                <svg viewBox="0 0 512 512">
                  <path
                    d="M180.36 73.78c0,-27.71 22.65,-50.35 50.35,-50.35l17.62 0 15.34 0 17.62 0c27.7,0 50.35,22.64 50.35,50.35l0 248.72 0 37.95 0 3.93c-21.37,37.05 -42.73,74.11 -64.1,111.16 -9.81,19.64 -14.65,15.07 -23.29,-0.44l-63.89 -111.23 0 -3.42 0 -24.99 0 -261.68zm125.01 283.59l0 -188.65 -98.74 0 0 188.19 49.37 86.12 49.37 -85.66zm-98.74 -214.92l98.74 0 0 -68.67c0,-13.23 -10.84,-24.07 -24.08,-24.07l-17.62 0 -15.34 0 -17.62 0c-13.24,0 -24.08,10.84 -24.08,24.07l0 68.67z"
                  />
                </svg>
              </a>
              <a class="table__btn-delete" @click="deleteGroup(group)"
                >&times;</a
              >
            </div>
            <div class="table__row row--desc" v-if="!group['is_default']">
              <p class="table__desc">
                {{ group['desc'] ? group['desc'] : 'توضیحاتی داده نشده است.' }}
              </p>
            </div>
          </div>
        </template>
      </div>
      <div class="round-btns">
        <button class="round-btn" v-if="groups.length < 10" @click="addGroup">
          <svg viewBox="0 0 512 512" class="round-btn__icon">
            <path
              d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
            />
          </svg>
          <span class="round-btn__text">افزودن</span>
        </button>
        <a class="round-btn" @click="$store.dispatch('goBack')">
          <svg
            class="round-btn__icon"
            viewBox="0 0 512 512"
            style="transform: rotate(90deg);width: 27px; height: 27px;"
          >
            <path
              d="M440.437,301.781L266.656,475.584V10.667C266.656,4.776,261.88,0,255.989,0
	c-5.891,0-10.667,4.776-10.667,10.667v464.917L71.541,301.781c-4.237-4.093-10.99-3.975-15.083,0.262
	c-3.992,4.134-3.992,10.687,0,14.82l192,192c4.16,4.171,10.914,4.179,15.085,0.019c0.006-0.006,0.013-0.013,0.019-0.019l192-192
	c4.093-4.237,3.975-10.99-0.262-15.083c-4.134-3.993-10.687-3.993-14.821,0L440.437,301.781z"
            />
            <path
              d="M255.989,512c-2.831,0.005-5.548-1.115-7.552-3.115l-192-192c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l184.469,184.448l184.448-184.448c4.237-4.093,10.99-3.975,15.083,0.262
	c3.993,4.134,3.993,10.687,0,14.821l-192,192C261.521,510.879,258.813,511.999,255.989,512z"
            />
            <path
              d="M255.989,512c-5.891,0-10.667-4.776-10.667-10.667V10.667C245.323,4.776,250.098,0,255.989,0
	c5.891,0,10.667,4.776,10.667,10.667v490.667C266.656,507.224,261.88,512,255.989,512z"
            />
          </svg>
          <span class="round-btn__text">بازگشت</span>
        </a>
      </div>
    </section>
    <modal
      :is-open="modals['edit']"
      @modalClosed="modals['edit'] = false"
      height="448px"
    >
      <div class="modal-inside">
        <form novalidate class="form-wrapper" @submit.prevent="submitForm">
          <div class="inputs-col">
            <base-input
              width="100%"
              label="نام سطح"
              animate-type="remove"
              v-model="selectedGroup['name']"
              star-label
            />
            <base-input-number
              label="مجموع خرید"
              style="width:100%;margin-top:15px;"
              rial
              star
              v-model="selectedGroup['price']"
            />
            <div class="bta-wrapper">
              <box-textarea
                label="توضیحات"
                v-model="selectedGroup['desc']"
                width="100%"
              />
            </div>
          </div>
          <div class="credit-buttons buttons--filter">
            <button
              type="button"
              class="button button--filter"
              @click="modals['edit'] = false"
            >
              <svg class="button__icon" viewBox="0 0 512 512">
                <path
                  d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
                />
              </svg>
            </button>
            <button type="submit" class="button button--filter button--accept">
              <svg
                class="button__icon"
                viewBox="0 0 512 512"
                v-show="!btnLoading"
              >
                <path
                  d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
                />
              </svg>
              <spinner bg-color="#ddd" v-if="btnLoading" />
            </button>
          </div>
        </form>
      </div>
    </modal>
    <modal
      :is-open="modals['delete']"
      @modalClosed="modals['delete'] = false"
      height="300px"
    >
      <div class="modal-inside">
        <form novalidate class="form-wrapper" @submit.prevent="submitForm">
          <p class="modal-confirm">
            آیا از حذف این "سطح" مطمئن هستید؟
          </p>
          <div
            class="credit-buttons buttons--filter"
            style="margin: 30px 0 20px;"
          >
            <button
              type="button"
              class="button button--filter"
              @click="modals['delete'] = false"
            >
              <svg class="button__icon" viewBox="0 0 512 512">
                <path
                  d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
                />
              </svg>
            </button>
            <button type="submit" class="button button--filter button--accept">
              <svg
                class="button__icon"
                viewBox="0 0 512 512"
                v-show="!btnLoading"
              >
                <path
                  d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
                />
              </svg>
              <spinner v-if="btnLoading" />
            </button>
          </div>
        </form>
      </div>
    </modal>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  addCustomerGroup,
  deleteCustomerGroup,
  editCustomerGroup
} from '@service/CustomerService'
import { reqString, schemaMaker, validateForms } from '@valid/JoiValidation'
import BaseInputNumber from '@common/BaseInputNumber'
import BoxTextarea from '@common/BoxTextarea'

export default {
  name: 'CustomerSort',
  components: {
    BaseInputNumber,
    BoxTextarea
  },
  setup() {
    const btnLoading = ref(false)
    const actionType = ref('')
    const modals = reactive({
      edit: false,
      delete: false
    })
    const selectedGroup = reactive({
      id: 1,
      name: '',
      price: 0,
      desc: ''
    })
    const addGroup = () => {
      selectedGroup['id'] = 1
      selectedGroup['name'] = ''
      selectedGroup['price'] = 0
      selectedGroup['desc'] = ''
      actionType.value = 'add'
      modals['edit'] = true
    }
    const editGroup = (group) => {
      selectedGroup['id'] = group['id']
      selectedGroup['name'] = group['name']
      selectedGroup['price'] = group['min_buy']
      selectedGroup['desc'] = group['desc']
      actionType.value = 'edit'
      modals['edit'] = true
    }
    const deleteGroup = (group) => {
      selectedGroup['id'] = group['id']
      actionType.value = 'delete'
      modals['delete'] = true
    }

    // API calls
    const store = useStore()
    const router = useRouter()
    store.dispatch('customer/fetchCustomerGroups')
    const groups = computed(() => store.state.customer.groups)
    const resolved = computed(() => groups.value.length > 0)
    const callAddGroup = async () => {
      const data = {
        name: selectedGroup['name'],
        price: selectedGroup['price'],
        desc: selectedGroup['desc']
      }
      btnLoading.value = true
      try {
        await addCustomerGroup(data)
        store.dispatch('addToast', {
          type: 'success',
          message: 'سطح مشتریان با موفقیت اضافه شد.'
        })
        modals['edit'] = false
        store.dispatch('customer/fetchCustomerGroups')
        router.push({ name: 'HomeStats' })
      } catch (ex) {
        if (ex.response)
          switch (ex.response.status) {
            case 409:
              store.dispatch('addToast', {
                type: 'error',
                message: 'مقدار حداقل خرید قبلاً اختصاص داده شده است.'
              })
              break
            default:
              store.dispatch('addToast', {
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
          }
      } finally {
        btnLoading.value = false
      }
    }
    const callDeleteGroup = async () => {
      const data = {
        id: selectedGroup['id']
      }
      btnLoading.value = true
      try {
        await deleteCustomerGroup(data)
        store.dispatch('addToast', {
          type: 'success',
          message: 'سطح مشتریان با موفقیت حذف گردید.'
        })
        router.push({ name: 'HomeStats' })
        store.dispatch('customer/fetchCustomerGroups')
        modals['delete'] = false
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      } finally {
        btnLoading.value = false
      }
    }
    const callEditGroup = async () => {
      const data = {
        id: selectedGroup['id'],
        name: selectedGroup['name'],
        price: selectedGroup['price'],
        desc: selectedGroup['desc']
      }
      btnLoading.value = true
      try {
        await editCustomerGroup(data)
        store.dispatch('addToast', {
          type: 'success',
          message: 'سطح مشتریان با موفقیت اصلاح گردید.'
        })
        modals['edit'] = false
        store.dispatch('customer/fetchCustomerGroups')
        router.push({ name: 'HomeStats' })
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      } finally {
        btnLoading.value = false
      }
    }

    const schema = schemaMaker({
      name: reqString('نام سطح')
    })
    const submitForm = async () => {
      if (btnLoading.value) return
      if (actionType.value !== 'delete') {
        const errors = validateForms({ name: selectedGroup['name'] }, schema)
        if (errors && errors.length > 0) {
          for (let key of errors) {
            const message = { type: 'error', message: key }
            store.dispatch('addToast', message)
          }
          return
        }
      }
      switch (actionType.value) {
        case 'add':
          await callAddGroup()
          break
        case 'edit':
          await callEditGroup()
          break
        case 'delete':
          await callDeleteGroup()
          break
        default:
          break
      }
    }

    return {
      btnLoading,
      groups,
      selectedGroup,
      modals,
      addGroup,
      editGroup,
      deleteGroup,
      actionType,
      callAddGroup,
      callDeleteGroup,
      callEditGroup,
      submitForm,
      resolved
    }
  }
}
</script>

<style lang="scss" scoped>
.inputs-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

$table-border-color: rgba(#000, 0.2);
.table {
  width: 96%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: none;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 0;
  // background: linear-gradient(to bottom, #ddd 56px, transparent 56px);
}
.table__part {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 15px;
  overflow: hidden;
}
.table__row {
  width: 100%;
  background-color: #fff;
  display: grid;
  padding: 0 10px 0 5px;
  grid-template-columns: 1fr 1fr 50px 50px;
  align-items: center;
  justify-items: center;
  column-gap: 5px;
  height: auto;
}
.table__cell {
  height: auto;
  width: 100%;
  text-align: right;
  padding: 20px 0;
  line-height: 20px;
  font-size: 14px;
}
.row--desc {
  background-color: #ddd;
}
.table__desc {
  grid-column: 1/-1;
  width: 100%;
  padding: 10px 0;
  font-size: 12px;
}
.cell--price {
  text-align: right;
}
.cell--price::after {
  content: 'R';
  position: relative;
  font-size: 10px;
  color: #222;
  top: -4px;
  right: -1px;
}
.table__btn-delete {
  width: 50px;
  height: 50px;
  line-height: 20px;
  font-size: 18px;
  padding: 4px 0 0;
  margin: 0;
  border-radius: 100%;
  color: #888;
  background-color: #c8c8c8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  position: relative;
}
.table__btn-edit {
  border-radius: 100%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transform: rotate(30deg);
  svg {
    fill: #777;
    width: 20px;
    height: 20px;
  }
}

.modal-inside {
  padding-top: 20px;
  background: linear-gradient(to bottom, #fff 40%, #eee 90%);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.modal-title {
  position: relative;
  bottom: -1px;
  height: 60px;
  width: 100%;
  // background-color: #ddd;
  color: #000;
  text-align: center;
  line-height: 60px;
}
.bta-wrapper {
  width: 100%;
  margin: 15px 0 0;
}

// change in common css
.cmp-wrapper {
  padding-top: 70px;
}
.round-btns {
  margin-top: 0;
}
.round-btn {
  background-color: #fff;
}
</style>
