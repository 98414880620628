<template>
  <div class="cmp-wrapper">
    <navbar msg="بونوس‌های مشتری" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="cmp-items-wrapper">
        <bonus-single
          v-for="bonus in bonuses"
          :key="bonus.id"
          :price="bonus['price']"
          :min-buy="bonus['min_buy']"
          :is-dark="bonus['status'] !== '1'"
          @click="() => enterBonus(bonus.id)"
        />
      </div>
      <div class="cmp-items--empty" v-if="bonuses.length === 0">
        برای این مشتری بونوس ایجاد نشده است.
      </div>
    </section>
    <failed-mode @refresh="getBonuses" v-else />
    <fixed-footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getCustomerBonuses } from '@service/CustomerService'
import BonusSingle from '@view/store/bonus/subComponents/BonusSingle'

export default {
  name: 'CustomerBonus',
  components: {
    BonusSingle
  },
  props: ['id'],
  setup(props) {
    // const store = useStore()
    const bonuses = ref(null)
    const pageStatus = ref('loading')

    const getBonuses = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getCustomerBonuses(props.id)
        bonuses.value = data
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    getBonuses()

    const router = useRouter()
    const enterBonus = (id) => {
      router.push({ name: 'BonusShow', params: { id } })
    }

    return { bonuses, pageStatus, enterBonus, getBonuses }
  }
}
</script>

<style scoped lang="scss">
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
