<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import dateConvertor from '@composable/dateConvertor'

const emit = defineEmits(['enterClicked', 'sellClicked'])
const props = defineProps({
  name: String,
  id: [Number, String],
  title: {
    type: String
  },
  image: {
    type: String
  },
  value: {
    type: [String, Number]
  },
  infoType: {
    type: String,
    default: 'money'
  }
})
const flip = ref(false)
const { localizeDate } = dateConvertor()

const valueToShow = computed(() => {
  if (props.infoType === 'date') return localizeDate(props.value)
  else if (props.infoType === 'money') return props.value.toLocaleString()
  else return props.value
})
</script>

<template>
  <div class="customer" @click.self="emit('enterClicked')">
    <div class="customer__img">
      <img v-show="flip" :src="image" @load="flip = true" />
      <svg
        v-if="!flip"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 512 512"
      >
        <circle fill="#606062" cx="256" cy="256" r="256" />
        <path
          fill="#373435"
          d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
        />
      </svg>
    </div>
    <div class="customer__middle">
      <h3 class="customer__name" v-text="name" />
      <div class="customer__info">
        <h4 class="customer__title" v-text="title + ':'" />
        <h5
          :class="['customer__value', infoType === 'money' && 'value--rial']"
          v-text="valueToShow"
        />
      </div>
    </div>
    <div @click="emit('sellClicked')" class="customer__sell">
      <svg viewBox="0 0 700 512">
        <path
          d="M191.19 113.94l117.76 0c7.16,-0.65 12.57,-6.7 12.57,-13.77 0,-7.17 -5.41,-13.13 -12.57,-13.78l-0.56 -0.09 -69.08 0 0 -73.08 0 -0.65c-0.65,-7.08 -6.61,-12.57 -13.77,-12.57 -7.17,0 -13.13,5.49 -13.78,12.57l0 0.56 0 73.17 -20.57 0c-50.46,0 -91.79,41.33 -91.79,91.78 0,50.46 41.33,91.79 91.79,91.79l68.7 0c35.28,0 64.14,28.77 64.14,64.05 0,35.28 -28.86,64.14 -64.14,64.14l-117.21 0 -0.55 0.09c-7.17,0.65 -12.57,6.61 -12.57,13.68 0,7.17 5.4,13.13 12.57,13.78l0.55 0.09 69.08 0 0 73.08 0 0.65c0.65,7.08 6.61,12.57 13.78,12.57 7.16,0 13.12,-5.49 13.77,-12.57l0 -0.56 0 -73.17 20.58 0c50.45,0 91.78,-41.33 91.78,-91.78 0,-50.46 -41.33,-91.7 -91.78,-91.7l-68.7 0c-35.38,0 -64.14,-28.86 -64.14,-64.14 0,-35.28 28.85,-64.14 64.14,-64.14l0 0 0 0zm323.12 42.17l-27.19 0 0 86.3 -86.29 0 0 27.18 86.29 0 0 86.3 27.19 0 0 -86.3 86.29 0 0 -27.18 -86.29 0 0 -86.3z"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.customer {
  width: 96%;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  display: grid;
  grid-template-columns: 80px 1fr;
  overflow: hidden;
  border-radius: 15px;
  margin: 0 0 10px;
  padding: 10px 20px 10px 0;
}
.customer__img {
  pointer-events: none;
  padding: 0;
  width: 100%;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    filter: saturate(0.3);
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
  }
  svg {
    width: 80px;
    height: 80px;
  }
}
.customer__middle {
  width: 100%;
  padding: 0 6% 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: none;
}
.customer__name {
  color: #111;
  margin: 15px 0 0px;
  text-align: right;
  font-size: 16px;
  // font-weight: bold;
}
.customer__info {
  padding: 0 0 0;
  display: flex;
  align-items: center;
  height: 100%;
}
.customer__title {
  color: #777;
  margin: 0 0 0 8px;
  padding-bottom: 2px;
  text-align: right;
  line-height: 28px;
  // font-weight: bold;
  font-size: 14px;
}
.customer__value {
  margin: 0;
  font-size: 14px;
  // font-weight: bold;
  color: #c69d4e;
}
.value--rial::after {
  content: 'R';
  position: relative;
  font-size: 9px;
  top: -3px;
}
.customer__sell {
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #373435;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 60px;
    height: 60px;
    fill: #c69d4e;
  }
}
</style>
